<template>
    <div class="product-page-container">
        <StackRouterHeaderBar :title="$translate('IN_APP_STORE')" :show-title="true" />
        <div class="product-page">
            <div class="introduce-product">
                <div class="introduce-card" v-for="item in introduceProducts" :key="item.id">
                    <div class="title f-16" v-html="item.title" />
                    <div class="content f-12 m-t-8" v-html="item.content" />
                </div>
            </div>

            <div class="my-point m-t-20">
                <div>보유 포인트</div>
                <div class="point-count">
                    <img class="img-detail m-r-10" src="@/assets/images/store/point_emoji.svg" alt="" />
                    <span class="">{{ `${point.toLocaleString()}개` }}</span>
                </div>
            </div>

            <div class="items">
                <PointItem
                    v-for="item in pointItems"
                    :key="item.id"
                    :item="item"
                    :selectedItem="selectedProduct"
                    @click="onClickItem"
                />
            </div>
            <!-- <div class="blank"></div> -->
        </div>

        <div class="refund p-13">
            <div class="refund-title f-16 m-b-10">환불 및 유의 사항</div>
            <ul class="refund m-l-20 m-r-20">
                <li
                    v-for="item in refundDescriptionLists"
                    :key="item.id"
                    class="refund-content f-13"
                    style="list-style-type: disc"
                    v-html="item.description"
                />
            </ul>
        </div>
    </div>
</template>

<script>
import PointItem from '@/routes/my-page/components/PointItem'
import productService from '@/services/product'

export default {
    name: 'InAppStorePage',
    components: {
        PointItem,
    },
    data: () => ({
        pointItems: null,
        selectedProduct: null,
        isSelected: false,
        introduceProducts: [
            {
                id: 1,
                title: '포인트는 어디에 쓰나요?',
                content: `•프로필 더 받기, 상세 프로필 보기, 진행상황 확인하기 등에 활용할 수 있습니다. <br/> •만남권과는 별도 상품입니다.`,
            },
        ],
        refundDescriptionLists: [
            {
                id: 1,
                description: `모든 상품은 VAT가 포함된 가격입니다.`,
            },
            {
                id: 2,
                description: `포인트는 구매 일시 기준 2년 동안 사용 가능합니다.`,
            },
            {
                id: 3,
                description: `구매 완료 후 포인트를 사용하지 않은 경우 7일 이내에 환불이 가능합니다. (포인트 사용 시 부분 환불이 불가합니다.)`,
            },
        ],
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        point() {
            if (this.$store.getters.badges) {
                return this.$store.getters.badges.point
            } else {
                return 0
            }
        },
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            try {
                const { point } = await productService.all()
                this.pointItems = point.sort((a, b) => b.id - a.id)
                await this.$store.dispatch('loadBadges')
            } catch (e) {}
        },
        onClickItem(item) {
            this.selectedProduct = item
            this.$store.dispatch('requestInAppPurchase', item)
        },
    },
}
</script>

<style lang="scss" scoped>
.product-page {
    padding: 20px;
    overflow-y: auto;

    .introduce-card {
        background-color: #fdfcfa;
        box-shadow: 0px 2px 20px 0px #0000001a;
        border-radius: 4px;
        padding: 14px 12px;

        .title {
            color: #151360;
            font-weight: 700;
        }

        .content {
            color: #7c7c7c;
            line-height: 18px;
        }
    }

    .my-point {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .point-count {
            display: flex;
            align-items: center;

            .img-detail {
                width: 20px;
            }
        }
    }

    .blank {
        height: 50px;
    }

    .purchase-button {
        bottom: 0px;
        padding-bottom: 20px;
        width: calc(100% - 40px);
        // margin: 16px 0;
        // padding: 0 4px;
        height: 68px;
        position: fixed;
        background: #fff9ef;
        .purchase-btn {
            width: 100%;
            height: 100%;
            border: none;
            background: #151360;
            border-radius: 36px;
            color: #f7f7f4;
        }
    }
}
.refund {
    .refund-title {
        color: #666b70;
        font-weight: 400;
    }
    .refund-content {
        color: #94999e;
        font-weight: 500;
        line-height: 23.4px;

        .refund-highlight {
            color: #7b7b7b;
            font-weight: 600;
        }
    }
}
</style>
