<template>
    <div class="product-item" :class="{ 'no-badge': !badgeText, selected }" @click="onClickItem(item)">
        <div class="image-tag">
            <img class="img-detail" :src="imageTag" alt="" />
        </div>
        <div class="content">
            <img class="img-detail m-r-10" src="@/assets/images/store/point_emoji.svg" alt="" />
            <div class="name f-17 f-medium">{{ item.name }}</div>
        </div>
        <div class="price-description f-7">
            <!-- <div class="pre-price">
                정가
                <span class="price f-12">
                    {{ Number(item.original_price) }}
                </span>
            </div> -->
            <div v-if="discountRate" class="discount-rate f-14 f-bold">{{ discountRate }}% 할인</div>
            <div class="result-price f-8">
                {{ discountRate ? '할인가' : '정가' }}
                <span class="price f-14"> {{ item.selling_price.toLocaleString() }}원 </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PointItem',
    props: ['item', 'selectedItem'],
    data: () => ({
        selected: false,
    }),
    watch: {
        selectedItem() {
            if (this.selectedItem.code === this.item.code) {
                this.selected = true
            } else {
                this.selected = false
            }
        },
    },
    computed: {
        badgeText() {
            if (!this.item) return ''

            if (this.item.discount > 40) {
                return `<span>실속!</span><span class="f-bold">${this.item.discount}%</span><span>할인</span>`
            }

            if (this.item.discount > 15) {
                return `<span>인기!</span><span class="f-bold">${this.item.discount}%</span><span>할인</span>`
            }

            if (this.item.discount > 0) {
                return `<span class="f-bold">${this.item.discount}%</span> 할인`
            }

            return ''
        },
        discountRate() {
            // return Math.round((this.item.discount / (this.item.original_price * (10 / 11))) * 100)
            if (this.item.code === 'point_300') {
                return 58
            } else if (this.item.code === 'point_170') {
                return 52
            } else if (this.item.code === 'point_90') {
                return 47
            } else if (this.item.code === 'point_50') {
                return 43
            } else if (this.item.code === 'point_30') {
                return 37
            } else {
                return null
            }
        },
        imageTag() {
            if (this.item.code === 'point_300') {
                return require(`@/assets/images/icons/value_tag.png`)
            } else if (this.item.code === 'point_50') {
                return require(`@/assets/images/icons/Best_tag.png`)
            } else {
                return ''
            }
        },
    },
    methods: {
        onClickItem(item) {
            // const { target } = event
            // target.classList.remove('selected')
            // target.classList.add('selected')
            // if (this.item.code === this.selectedItem.code) {
            //     this.selected = !this.selected
            // } else {
            //     this.selected = false
            // }
            this.$emit('click', item)
        },
    },
}
</script>

<style scoped lang="scss">
.product-item {
    position: relative;
    display: flex;
    margin-top: 20px;
    background: #ffffff;
    height: 80px;
    border-radius: 8px;
    border: 1px solid #ffd2a9;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .content {
        @include center;
        display: flex;
        align-items: center;
        .img-detail {
            width: 24px;
        }
    }

    &.selected {
        background: #ffe9e9;
    }

    .image-tag {
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 8px;
        width: 45px;
        height: 19px;
        @include center;
        .img-detail {
            width: 45px;
        }
    }
}
.price-description {
    margin: 0 20px;
    position: absolute;
    right: 0;
    text-align: right;
    color: #5c5c77;

    .discount-rate {
        margin: 4px 0;
        font-weight: 600;
        color: #ff8c8c;
    }

    .pre-price {
        .price {
            font-weight: 600;
            // text-decoration: line-through;
        }
    }

    .result-price {
        .price {
            font-weight: 600;
        }
    }
}
</style>
